import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import Slider from '../components/slider';
import About from '../components/about';
import Patients from '../components/patients';
import SleepDisorders from '../components/sleepDisorders';
import Physicians from '../components/physicians';
import Contact from '../components/contact';
import SocialMedia from '../components/socialMedia';

const IndexPage = () => {
  return (
    <Layout>
      <Head />
      <Slider />
      <About />
      <Patients />
      <SleepDisorders />
      <Physicians />
      <Contact />
      <SocialMedia />
    </Layout>
  )
}

export default IndexPage;