import React from 'react';

const SleepDisorders = () => {
    return (
    <section id="sleep_disorders">
    <div className="container">
        <h1>Sleep Disorders</h1>
        <p>According to the American Sleep Disorders Association, there are over 80 sleep disorders that affect people. The most common are the following (in no particular order):</p>
        <br/>
        <h2>1. Snoring</h2>
        <p>Snoring affects 45% of normal adults and can be a signal that something is seriously wrong with one's breathing during sleep.</p>
        <br/>
        <h2>2. Sleep Apnea</h2>
        <p>Apnea is a Greek word meaning "without breath." Sleep Apnea is a potentially life-threatening medical condition characterized by repetitive pauses or significant reduction is airflow during sleep.  This condition refers to an obstruction caused by the narrowing of the airway in the throat. When the upper airway collapses on an OSA sufferer who is sleeping, the brain detect his or her restricted access to oxygen.  When this happens, the brain rouses the patient from a deep sleep to a semi-conscious state that enables the patient to resume breathing so as to obtain the needed oxygen.  The sleep arousal interrupts the sleep cycle that is required for rest and so causes excessive daytime sleepiness that can result, because of fatigue, in injury to the sufferer or to others.</p>
        <br/>
        <h3>There are three types of sleep apnea:</h3>
        <div className="center-text">
            <br/>
            <h3>Obstructive Sleep Apnea (OSA)</h3>
            <p>The most common form of sleep apnea. Usually caused by soft tissue and musculature in the upper airway (throat) blocking the flow of air in and out of your lungs during sleep.</p>
            <br/>
            <h3>Central Sleep Apnea (CSA)</h3>
            <p>CSA results from the brain not signaling your respiratory system to breath.</p>
            <br/>
            <h3>Mixed Sleep Apnea (MSA)</h3>
            <p>MSA is a combination of OSA and CSA. Also now being referred to can "Complex Sleep Apnea."</p>
        </div>
        <br/>
        <h2>3. Narcolepsy</h2>
        <p>This is a relatively uncommon disorder which involves the body's central nervous system and is characterized by attacks of sleep.  Narcolepsy affects about 1 in 2,000 people, both men and women of any age.  A person with Narcolepsy is likely to fall asleep at inappropriate times and places. Daytime sleep attacks may occur with or without warning and can occur repeatedly in a single day.  Nighttime sleep may be fragmented with frequent arousals.</p>
        <br/>
        <h2>4. Insomnia</h2>
        <p>This is the inability to fall asleep or stay asleep.  It may be related to anxiety, depression, medical/psychiatric problems or substance abuse. Insomnia is a symptom of a larger problem and in order to treat it the physician must find the cause. Most insomnia sufferers sleep better after the appropriate evaluation and treatment.</p>
        <br/>
        <h2>5. Periodic Limb Movement Disorder (PLMD)</h2>
        <p>Refers to the involuntary movements of the legs and / or arms during sleep. These movements cause arousals and sleep fragmentation. PLMD is observed mostly in people over 50 years of age.</p>
    </div>
    </section>
    )
};

export default SleepDisorders;