import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';


const Contact = () => {
    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title,
                addressStreet,
                addressCity,
                addressStateZip,
                phone,
                chinesePhone,
                email,
                fax,
                formSpreeURL,
                googleMapsURL
            }
        }
    }
    `);

    const initialState = { status : "" };
    const [ currentData, updateData ] = useState(initialState);
    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
            form.reset();
            updateData({ status: "SUCCESS" });
        } else {
            updateData({ status: "ERROR" });
        }
        };
        xhr.send(data);
  }
    const { title, addressStreet, addressCity, addressStateZip, phone, chinesePhone, email, fax, formSpreeURL, googleMapsURL } = data.site.siteMetadata;
    return (
        <section id="contact">
        <div className="container">
            <h1>Contact Us</h1>
            <div className="row">
            <div className="col-md-12 contact-info">
            <h4 className="forward">We are looking forward to hearing from you!</h4>
                <div className="follow"><b>Phone:  </b><i className="fa fa-phone"></i><a href={`tel:+1 ${phone}`}>{phone}</a>
                </div>
                {chinesePhone ? <div className="follow"><b>中文:  </b><i className="fa fa-phone"></i><a href={`tel:+1 ${chinesePhone}`}>{chinesePhone}</a>
                </div> : <div/>}
                <div className="follow"><b>Fax:  </b><i className="fa fa-fax"></i>{fax}
                </div>
                <div className="follow"><b>Email:  </b><i className="fa fa-envelope-o"></i><a id="contactEmail" href={`mailto:${email}`}>{email}<br/><br/></a>
                <p>{`I have read and understand ${title} Center's `}<a href={require("../assets/privacy_notice.pdf")} target="_blank" rel="noreferrer" title="Read the Privacy Notice">Privacy Notice</a>.</p>
                </div>
                <div className="follow"><b>Address:  </b><i className="fa fa-map-marker"></i>
                    {`${title} Center,
                    ${addressStreet},
                    ${addressCity}, ${addressStateZip}`}
                </div>
                <iframe src={googleMapsURL} title="location" height="450" frameBorder="0" style={{ border:"0", width: "100%"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
            </div>
        </div>
        </section>
    );
};

export default Contact;