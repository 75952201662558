import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'react-bootstrap/Carousel';
import Img from "gatsby-image";

const Slider = () => {
      const data = useStaticQuery(graphql`
        query {
          image1: file(relativePath: {eq: "rem_img1.jpg"}) {
            childImageSharp {
              fluid (maxWidth: 1800){
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2: file(relativePath: {eq: "rem_img2.jpg"}) {
            childImageSharp {
              fluid (maxWidth: 1800){
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3: file(relativePath: {eq: "rem_img3.jpg"}) {
            childImageSharp {
              fluid (maxWidth: 1800){
                ...GatsbyImageSharpFluid
              }
            }
          }
          site {
            siteMetadata {
              title
              addressCity
            }
        }
      }
      `);
      return (
        <Carousel id="slider">
        <Carousel.Item>
          <Img fluid={data.image1.childImageSharp.fluid} alt=""/>
          <Carousel.Caption className="carousel-caption">
            <h4>{`Welcome to the ${data.site.siteMetadata.title} Center`}<br/> Obstructive Sleep Apnea & Snoring Treatment Center</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.image2.childImageSharp.fluid} alt=""/>
          <Carousel.Caption className="carousel-caption">
            <h5>{`We are a community-based sleep center in ${data.site.siteMetadata.addressCity}, California and from the beginning; our main objective has been to offer high quality and incomparable overnight sleep producers, treatment and patient care. Timely results, efficiency, professionalism and deliver a friendly environment.`}</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.image3.childImageSharp.fluid} alt=""/>
          <Carousel.Caption className="carousel-caption">
            <h5>We offer a comprehensive clinical facility that diagnoses and treats sleep disorders. Our fully equipped sleep center offer full polysomnography testing as well as and arrange appropriate treatment options.</h5>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
};

export default Slider;

