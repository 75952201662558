import React from 'react';
import { useEffect } from 'react';

const SocialMedia = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://static.addtoany.com/menu/page.js";
      script.async = true;
      document.body.appendChild(script);
    return () => {
        document.body.removeChild(script);
      }
    }, []);

    return (
    <div className="a2a_kit a2a_kit_size_32 a2a_floating_style a2a_default_style" style={{ bottom:"0px", right:"0px" }}>
        <a className="a2a_button_facebook"></a>
        <a className="a2a_button_twitter"></a>
        <a className="a2a_button_pinterest"></a>
        <a className="a2a_dd" href="https://www.addtoany.com/share"></a>
    </div>
    )
};

export default SocialMedia;