import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";

const Physicians = () => {
      const data = useStaticQuery(graphql`
        query {
          image1: file(relativePath: {eq: "prescription.jpg"}) {
            childImageSharp {
              fluid (maxWidth: 600){
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2: file(relativePath: {eq: "epworth.jpg"}) {
            childImageSharp {
              fluid (maxWidth: 600){
                ...GatsbyImageSharpFluid
              }
            }
          }
      }`);

    return (
    <section id="physicians">
    <div className="container">
        <h1>Physicians</h1>
        <div className="row">
        <div className="col-md-6">
            <Img fluid={data.image1.childImageSharp.fluid} alt="prescription" className="images"/>
        <a href={require("../assets/prescription.pdf")} target="_blank" className="btn btn-primary">Download Prescription Form</a>
        </div>
        <div className="col-md-6">
            <Img fluid={data.image2.childImageSharp.fluid} alt="epworth" className="images"/>
            <a href={require("../assets/RemEpworth.pdf")} target="_blank" className="btn btn-primary">Download Epworth Form</a>
        </div>
        </div>
    </div>
    <div id="diagnostic_services" className="diagnostic_services">
        <h2>Diagnostic Services <i className="fa fa-cogs"></i></h2>
        <br/>
        <h3>Polysomnography (PSG) CPT Code 95810</h3>
        <p>This is the initial in lab sleep study to confirm sleep disorders diagnosis.</p>
        <h3>CPAP (CPAP Polysomnography Titration) CPT Code 95811</h3>
        <p>This is the titration sleep study to fit the patient to the pressure of the CPAP/BiPaP machine. During the titration, the patient wears a mask and CPAP pressures are trialed up and down during the sleep to find a single best pressure delivery and setting to control apnea events.</p>
        <h3>Split Night Polysomnography (PSG) CPT Code 95811</h3>
        <p>This sleep study is a Polysomnography (PSG) and CPAP Titration study done in one night. The CPAP titration study can only be started if certain conditions are met during the Polysomnography so we cannot guarantee that both can be done on the same night.</p>
        <h3>Multiple Sleep Latency Test (MSLT) CPT Code 95805</h3>
        <p>This is a nap sleep study done the morning after the Baseline Polysomnography (PSG) (This goes right along with the baseline study). This is to rule out other diagnoses and help patients who complain of excessive daytime sleepiness. The test measures the time elapsed from the start of a daytime nap period to the first signs of sleep, called sleep latency. The test is based on the idea that the sleepier people are, the faster they will fall asleep. The Multiple Sleep Latency Test (MSLT) is used extensively to test for narcolepsy.</p>
        <h3>Maintenance of Wakefulness Test (MWT) CPT Code 95810</h3>
        <p>This is a sleep study done the morning after the Baseline Polysomnography (PSG) (This goes right along with the baseline study). It measures your ability to stay awake and alert during the day.</p>
        <h3>Unattended Home Sleep Study (HST)(Portable Diagnostic Polysomnography (PSG) CPT Code 95806 or G0399</h3>
        <p>This is a home sleep study test tool that is used for the diagnosis of obstructive sleep apnea. Most home sleep study test (HST) devices are portable and are also call “Unattended Sleep Study”. </p>
        <h3>The Difference between Home Sleep Test (HST) and Lab Sleep Test (PSG) Home Sleep Studies (HST)</h3>
        <p>The home sleep test (HST) is administered by the patient in the comfort of their home. It is used to test patients with obstructive sleep apnea, and it is not recommended for patients with other sleep disorders or who have certain medical conditions such as congestive heart failure (CHF), chronic obstructive pulmonary diseases (COPD) or neuromuscular diseases.</p>
        <h3>What It Measures (HST)</h3>
        <p>The home sleep test (HST) measures oxygen saturation, heart rate, airflow, movement in chest and abdomen, snoring and sleep position. A sleep test done in a lab measures all the things mentioned above, as well as br/ain waves, sleep stages waves (Stages 1,2,3, and REM (Rapid Eye Movement), sleep time, and leg movements (an indicator of restless leg syndrome (RLS) and br/eath waves (Abdomen, Diaphragm and chest).</p>
        <h3>Lab Sleep Test (PSG)</h3>
        <p>The lab sleep test Overnight Polysomnography (PSG) it is a diagnostic procedure in a sleep lab facility full attended by sleep technicians that are observing an analyzing the sleep study procedure during the night while the patient is asleep such as br/ain activity (Electroencephalography (EEG)), respiratory function (Airflow through mouth and nose), muscle function (Electromyography (EMG)), heart rate (Electrocardiography (ECG)), Snore and a variety of other variables that are essential for the diagnosis of the more complex sleep disorders. Polysomnography (PSG) is particularly useful for the diagnosis of Obstructive Sleep Apnea (OSA) and Sleep Disordered br/eathing, Narcolepsy, Parasomnias, Restless Leg Syndrome (RLS) and Neurological Disorders such as Epilepsy.</p>
        <h3>Polysomnography (PSG) and Multiple Sleep Latency Testing (MSLT)</h3>
        <p>This is the sleep study test procedure recognized and generally accepted for diagnosis of Narcolepsy.</p>
        <h3>Pediatric Sleep Studies</h3>
        <p>Sleep test studies are provided to children when referred by a primary doctor, pediatrician or ENT (Otolaryngologist) specialist.</p>
    </div>
    </section>
    )
};

export default Physicians;