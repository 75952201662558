import React from 'react';
import { Link } from 'react-scroll';
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `);
    return (
    <section id="about">
    <div className="container">
    <h1>About Us</h1>
    <div className="about-content">
        <h2>{`${data.site.siteMetadata.title} Center`}</h2>
        <p>Our sleep center is a new and innovative facility. We provide an accurate and prompt diagnosis of various sleep problems including sleep apnea and snoring treatments to help identify the most appropriate treatment for you. Problem snoring and sleep apnea are a more widespread problem than most people think. Often people do not even realize that they are suffering. If you are concerned that you might have a sleep problem, you can arrange to see your private physician for a consultation.</p>
        <h2>Our Goal</h2>
        <p>Our goal is to deliver high quality clinical care in a friendly environment. We are very kind to provide an excellent quality of care. After your overnight sleep study you will be kindly asked to fill in a questionnaire following your about your night stay, this will help us to continually improve our quality of care.</p>
        <h2>Long-Term Care</h2>
        <p>We provide rapid referral access to specialist in sleep disorders and with medical equipment such as CPAP machines or masks. This is very important as we understand that continuity of treatment is crucial for patients that are suffering from sleep apnea and problem with snoring. We use advanced computing technology to remotely monitor our patients on therapy to reduce the number of clinic visits. A 24 hour support phone number is provided to our patients to help them with any concerns they may have with their sleep disorder treatment therapy.</p>
        </div>
        <Link activeClass="active" className="btn btn-primary" to="contact" offset={-70} spy={true} smooth={true} duration={1000}>
            Contact Us
        </Link>
    </div>
    </section>
    )
};

export default About;