import React from 'react';
import { Link } from 'react-scroll';

const Patients = () => {
    return (
    <section id="patients">
        <div className="container">
            <h1>Patients</h1>
            <div className="row patients">
                <div className="container">
                    <h2>Do I Need A Sleep Study?</h2>
                    <p>There are many symptoms associated with sleep problems. Some symptoms are more obviously indicative
                        of a sleep disorder such as trouble falling or staying asleep, loud snoring, low energy levels or
                        problems with staying awake during the day. If you think you may be suffering of a sleep problems or
                        are experiencing extreme daytime sleepiness, consult your private physician or primary care
                        practitioner and ask for a referral to our sleep center. Once we receive the referral, our office
                        will contact you to schedule an overnight sleep study at your convenience.</p>
                </div>
                <div className="container">
                    <div className="icon">
                        <i className="fa fa-bed"></i>
                    </div>
                    <h2>Signs of Sleep Apnea</h2>
                    <div className="signs">
                    <p>Warning signs to look out for:</p></div>
                        <ul>
                        <li>Loud snoring</li>
                        <li>Breath holding at night (apnea)</li>
                        <li>Daytime fatigue</li>
                        <li>Short term memory loss</li>
                        <li>Multiple trips to urinate through the night</li>
                        <li>Early morning headaches</li>
                        <li>Loss of interest in sex</li>
                        <li>Obesity or difficulty losing weight</li>
                        <li>Unexplained shortness of breath</li>
                        <li>Mood Disorders such as depression, anxiety, panic attacks or stress</li></ul>
                </div>
                <br/>
                <iframe width="560" height="315" title="OSA" src="https://www.youtube.com/embed/JuHdypjAczw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <div className="container">
                    <p>If you are suffering from any of the above, ask our Doctor for a referral to our clinic.</p>
                    <a href={require("../assets/prescription.pdf")} rel="noreferrer" target="_blank" className="btn btn-primary">Download Prescription Form</a>
                    <p>Our service provides accurate and prompt diagnosis of the causes
                        of sleep apnea and identifies the most appropriate treatment for our patients. Everyone is
                        different, and this is something that we will take into account when analyzing your sleep and
                        deciding upon a treatment.</p>
                    <br/>
                    <h2>Types of Sleep Tests</h2>
                    <p>Polysomnography (PSG), Multiple Sleep Latency Test (MSLT), CPAP Polysomnography Titration, Split Night Polysomnography and Maintenance of Wakefulness Test (MWT), Unattended Home Sleep Study (HST).</p>
                    <Link activeClass="active" className="btn btn-primary" to="diagnostic_services" offset={-70} spy={true} smooth={true} duration={1000}>
                        Diagnostic Services
                    </Link>
                </div>
            </div>
        </div>
    </section>
    )
};

export default Patients;